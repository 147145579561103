.swiper {
  padding: 8px;
  max-width: 100%;
}
.swiper-wrapper {
  position: relative;
  min-height: 600px;
  align-items: center;
}
.swiper-pagination {
  z-index: 0;
}
.home-slider .swiper-button-prev {
  background-image: url("/src/assets/48px/arrow/short/left.svg");
  width: 48px;
  height: 48px;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}
.home-slider .swiper-button-next {
  background-image: url("/src/assets/48px/arrow/short/right.svg");
  width: 48px;
  height: 48px;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 0;
}

.swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  border-radius: 0;
  opacity: 1;
  background-color: #99B6B9;
  margin: 0 10px !important;
}
.swiper-pagination-bullet-active {
  background-color: #fff;
  opacity: 1;
 }

.swiper-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic, .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 29%;
  width: 100px !important;
}


.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
  top: 17px;
  left: 46px;
  display: inline-block;
  width: auto;
}
